import React from 'react'

import _sortBy from 'lodash-es/sortBy'

import { ChoicePayment } from '@igs-web/common-models/models/choice-transaction-history'
import { LegacyPayment } from '@igs-web/common-models/models/legacy-data'
import { BillingAccountReportRequest } from '@igs-web/common-models/models/report-requests/billing-account-report-request'
import { customerInfoApiClient } from '@igs-web/common-utilities/api/customer-info-api-client'
import { downloadBlob } from '@igs-web/common-utilities/utilities/document-utilities'

import { useCompany } from '../company/hooks/useCompany'
import { DataTable } from '../data-table/data-table'
import { DefaultEmptyTable } from '../data-table/default-empty-table'
import { DateFilter } from '../data-table/filters/date-filter'
import { TableColumn } from '../data-table/models/table-column'
import { TableDataType } from '../data-table/models/table-data-types'

import { dedupeChoicePayments } from './business/billing-service'

interface PaymentTableRecord {
    readonly date: Date
    readonly amount: number
    readonly paymentType: string
}

const getSortedRecords = (payments: ReadonlyArray<PaymentTableRecord>) => {
    return _sortBy(
        payments,
        r => r.date,
        r => r.amount,
        r => r.paymentType,
    ).reverse()
}

const mapToTableRecord = ({ date, amount, paymentType, isCancelled }: ChoicePayment): PaymentTableRecord => ({
    date,
    amount,
    paymentType: `${paymentType}${isCancelled ? ' (Cancelled)' : ''}`,
})

const mapLegacyToTableRecord = ({ date, amount }: LegacyPayment): PaymentTableRecord => ({
    date,
    amount: Math.abs(amount),
    paymentType: 'No Data',
})

const downloadPdf = async (accountId: number) => {
    const blob = await customerInfoApiClient.getPaymentsPdfByAccountId(accountId)
    downloadBlob(blob, 'payments', 'pdf')
}

const downloadCsv = async (accountId: number) => {
    const blob = await customerInfoApiClient.getPaymentsCsvByAccountId(accountId)
    downloadBlob(blob, 'payments', 'csv')
}

const columns: ReadonlyArray<TableColumn> = [
    { header: 'Payment Date', accessor: 'date', dataType: TableDataType.DateTime, filter: DateFilter },
    { header: 'Amount', accessor: 'amount', dataType: TableDataType.Currency, disableSorting: true },
    { header: 'Payment Type', accessor: 'paymentType', dataType: TableDataType.String },
]

export const PaymentHistoryTable = ({ accountId, payments }: OwnProps): JSX.Element => {
    const paymentData = dedupeChoicePayments(payments).map(mapToTableRecord)
    const rowData = getSortedRecords([...paymentData])
    const { phoneNumber } = useCompany()

    return (
        <DataTable<PaymentTableRecord>
            tableTitle={'Payments'}
            columns={columns}
            data={rowData}
            handlePdfDownload={() => downloadPdf(accountId)}
            handleCsvDownload={() => downloadCsv(accountId)}
            showWhenEmpty={<DefaultEmptyTable tableDataType="payments" phoneNumber={phoneNumber.customerSupport} />}
            dataTestId="payment-history-table"
        />
    )
}

interface OwnProps {
    readonly accountId: number
    readonly payments: ReadonlyArray<ChoicePayment>
}

const downloadBillingAccountPdf = async (accountIds: ReadonlyArray<number>, billingAccountNumber: string, companyName: string) => {
    const request: BillingAccountReportRequest = {
        accountIds,
        billingAccountNumber,
        companyName,
    }
    const blob = await customerInfoApiClient.getBillingAccountPaymentsPdfByAccountIds(request)
    downloadBlob(blob, 'payments', 'pdf')
}

const downloadBillingAccountCsv = async (accountIds: ReadonlyArray<number>, billingAccountNumber: string, companyName: string) => {
    const request: BillingAccountReportRequest = {
        accountIds,
        billingAccountNumber,
        companyName,
    }
    const blob = await customerInfoApiClient.getBillingAccountPaymentsCsvByAccountIds(request)
    downloadBlob(blob, 'payments', 'csv')
}

export const BillingAccountPaymentHistoryTable = ({ accountIds, payments, legacyPayments, billingAccountNumber }: BillingAccountPaymentHistoryTableProps) => {
    const { displayName } = useCompany()

    const { phoneNumber } = useCompany()
    const paymentData = dedupeChoicePayments(payments).map(mapToTableRecord)

    const legacyPaymentData = legacyPayments.map(mapLegacyToTableRecord)

    const rowData = getSortedRecords([...paymentData, ...legacyPaymentData])

    const handlePdfDownload = () => downloadBillingAccountPdf(accountIds, billingAccountNumber, displayName)
    const handleCvsDownload = () => downloadBillingAccountCsv(accountIds, billingAccountNumber, displayName)
    return (
        <DataTable<PaymentTableRecord>
            tableTitle={'Payments'}
            columns={columns}
            data={rowData}
            handlePdfDownload={paymentData.length ? handlePdfDownload : undefined}
            handleCsvDownload={paymentData.length ? handleCvsDownload : undefined}
            showWhenEmpty={<DefaultEmptyTable tableDataType="payments" phoneNumber={phoneNumber.customerSupport} />}
            dataTestId="payment-history-table"
        />
    )
}

interface BillingAccountPaymentHistoryTableProps {
    readonly accountIds: ReadonlyArray<number>
    readonly payments: ReadonlyArray<ChoicePayment>
    readonly legacyPayments: ReadonlyArray<LegacyPayment>
    readonly billingAccountNumber: string
    readonly dataTestId: string
}
