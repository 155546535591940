import { AccountTypeName } from '@igs-web/common-models/constants/account-type'
import { ProductTypeDescription } from '@igs-web/common-models/models'
import { AccountStatus } from '@igs-web/common-models/models/account-model'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'

import { subtractDays } from '../utilities/date-utilities'

const DEFAULT_RENEWAL_WINDOW_LENGTH = 60 // days

export const canEnterNewContract = (account: UserProfileAccount): boolean => {
    const isVariable = account.productTypeCode?.toUpperCase() === ProductTypeDescription.Variable.toUpperCase()

    if (isVariable) {
        return false
    }

    const currentDate = new Date()
    const contractEndDate = account.termEndDate ? new Date(account.termEndDate) : undefined

    const isInRenewalPeriod = account.earlyTerminationFeeEnforcementEndDate
        ? account.earlyTerminationFeeEnforcementEndDate < currentDate.getTime()
        : !!contractEndDate && subtractDays(contractEndDate, DEFAULT_RENEWAL_WINDOW_LENGTH) < currentDate

    return (
        (!account.isFriendsAndFamily && isInRenewalPeriod && !account.hasPendingContract && account.accountType !== AccountTypeName.ChoiceIndustrial) ||
        account.status === AccountStatus.inactive
    )
}

export const canRenew = (account: UserProfileAccount, canRenewAnyStatus = true): boolean =>
    (canRenewAnyStatus || account.status === AccountStatus.active) && !!account.termEndDate && canEnterNewContract(account)
