import * as React from 'react'
import { Portal } from 'react-portal'

import styled from 'styled-components'

import { CloseButton } from '@igs-web/common-ui-components/_atoms/buttons/close-button'
import { useOnKeyPress } from '@igs-web/common-ui-components/hooks/useOnKeyPress'
import { Breakpoint } from '@igs-web/common-ui-components/styles/breakpoints'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { background, modalButtonColor, modalZindex, primaryDark } from '@igs-web/common-ui-components/styles/theme'

const EscapeKey = 'Escape'

const ModalWrapper = styled.div<ModalWrapperProps>`
    display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: ${modalZindex};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.6);
`
interface ModalWrapperProps {
    readonly $isOpen: boolean
}

const ModalContainer = styled.div<ModalContainerProps>`
    position: sticky;
    top: 25%;
    bottom: 25%;
    max-width: 95%;
    max-height: 95%;
    overflow: auto;
    background: ${({ $isDark }) => ($isDark ? primaryDark : background)};
    padding: ${Spacing.ExtraLarge};
    padding-top: ${Spacing.XxLarge};
    ${CloseButton} {
        position: absolute;
        top: ${Spacing.Large};
        right: ${Spacing.Large};
        background: none;
    }
    @media (max-width: ${Breakpoint.Mobile}) {
        max-width: 99%;
        padding: ${Spacing.Small};
    }
`

interface ModalContainerProps {
    readonly $isDark: boolean
}

export const Modal = (props: ModalProps) => {
    const { children, isOpen, closeOnClickAway = true, onClose, className, isDark, ...htmlProps } = props

    useOnKeyPress(EscapeKey, onClose)

    return (
        <Portal>
            <ModalWrapper $isOpen={isOpen} onClick={() => closeOnClickAway && onClose()}>
                <ModalContainer $isDark={!!isDark} className={className} {...htmlProps} onClick={e => e.stopPropagation()}>
                    <CloseButton $color={isDark ? 'white' : modalButtonColor} onClick={onClose} />
                    {isOpen && children}
                </ModalContainer>
            </ModalWrapper>
        </Portal>
    )
}

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
    readonly children: React.ReactNode
    readonly isOpen: boolean
    readonly onClose: () => void
    readonly isDark?: boolean
    readonly closeOnClickAway?: boolean
}
