import * as React from 'react'

import styled from 'styled-components'

import { useCompany } from '@igs-web/common-components/domain/company/hooks/useCompany'
import { ActiveFooter, ProductTitle, TermContainer } from '@igs-web/common-components/domain/plan-at-a-glance/account-container'
import { ContractLength } from '@igs-web/common-components/domain/plan-at-a-glance/contract-length'
import { PlanRate } from '@igs-web/common-components/domain/plan-at-a-glance/plan-rate-section'
import { TermRemainingText } from '@igs-web/common-components/domain/plan-at-a-glance/term-remaining'
import { ProductTypeDescription } from '@igs-web/common-models/models'
import { UserProfileAccount } from '@igs-web/common-models/models/user-profile-model'
import { Spacing } from '@igs-web/common-ui-components/styles/spacing'
import { MoneyFormat, formatAsMoney } from '@igs-web/common-utilities/utilities/currency-utilities'
import { getProductTitleForAccount } from '@igs-web/common-utilities/utilities/offer-utilities'

import { DashboardTileBody, HeaderLabel, ManageCell, StatusLabel } from '../../billing-dashboard-tile-styles'
import { successStatus } from '../account-options-tile'

import { PriceEffective } from './price-effective'

const ActivePlanContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spacing.Medium};
`
const FooterText = styled.p`
    font-weight: bold;
    line-height: ${Spacing.Medium};
    margin-bottom: 0px;
    margin-top: ${Spacing.Medium};
`

const AccountOptionsBody = styled(DashboardTileBody)`
    padding: ${Spacing.ExtraSmall};
`

const AutoRenewSection = ({ account }: AutoRenewSectionProps) => {
    const { termEndDate } = account
    const endDate = new Date(termEndDate ?? 0)
    return (
        <>
            <AccountOptionsBody>
                <HeaderLabel>Auto renew: </HeaderLabel>
                <StatusLabel style={successStatus}>Enrolled</StatusLabel>
                <ManageCell>Your plan automatically renews on {endDate.toLocaleDateString()}</ManageCell>
            </AccountOptionsBody>
        </>
    )
}

export const ActiveGasAccount = ({ account, isCommercialIndustrial, isAutoRenew }: ActiveGasAccountProps) => {
    const company = useCompany()
    const { rate, rateUnit, lineOfBusinessCode, termStartDate, termEndDate, productTypeCode } = account
    const isFixed = productTypeCode?.toUpperCase() === ProductTypeDescription.Fixed.toString().toUpperCase()
    const isVariable = productTypeCode?.toUpperCase() === ProductTypeDescription.Variable.toString().toUpperCase()
    const showTerm = !!termStartDate && !!termEndDate && !isVariable
    const showPriceEffective = isVariable && !isCommercialIndustrial
    const showFooter = isFixed || (isVariable && !isCommercialIndustrial)

    return (
        <ActivePlanContainer>
            <PlanRate $lineOfBusinessCode={lineOfBusinessCode}>
                {rate && (
                    <>
                        {formatAsMoney(rate, MoneyFormat.Dollars, false, 4, 4)}
                        <span>/{rateUnit}</span>
                    </>
                )}
                {!rate && <>Variable</>}
                {showPriceEffective && (
                    <>
                        <br /> <PriceEffective />
                    </>
                )}
            </PlanRate>
            <ProductTitle data-testid="product-title">{getProductTitleForAccount(account, company)}</ProductTitle>
            {showTerm && (
                <TermContainer data-testid="term-length-container">
                    <ContractLength startDateTicks={termStartDate ?? 0} endDateTicks={termEndDate ?? 0} />
                </TermContainer>
            )}
            {showFooter && (
                <ActiveFooter data-testid="active-footer">
                    <FooterText>
                        {isFixed && <TermRemainingText termEndDate={termEndDate} />}
                        {isVariable && (
                            <>
                                Price is subject to change on the 5th of every month. The variable price you will be billed is determined by the price in effect
                                on your meter read date.
                            </>
                        )}
                    </FooterText>
                </ActiveFooter>
            )}
            {isAutoRenew && <AutoRenewSection account={account} />}
        </ActivePlanContainer>
    )
}

interface AutoRenewSectionProps {
    readonly account: UserProfileAccount
}

interface ActiveGasAccountProps {
    readonly account: UserProfileAccount
    readonly isCommercialIndustrial: boolean
    readonly isAutoRenew: boolean
}
